const appConfig = {
  host: 'uat',

  setupHost() {
    const currentHost = window.location.host;
    switch (currentHost) {
      case 'access.qa.keenvil.com':
        this.host = 'qa';
        break;
      case 'access.uat.keenvil.com':
        this.host = 'uat';
        break;
      case 'access.v2.keenvil.com':
        this.host = 'prod';
        break;
      default:
        this.host = 'prod';
    }
  },

  apiKey: 'key',

  debugMode() {
    return this.host === 'uat';
  },

  apiBaseUrlMap: {
    qa: 'http://api.uat.keenvil.com:8080',
    uat: 'http://api.uat.keenvil.com:8080',
    prod: 'https://api.v2.keenvil.com',
  },

  zktecoApiUrl: 'https://api.keenvil.com:8080',

  getApiBaseUrl() {
    return this.apiBaseUrlMap[this.host];
  },

  sentryDsn: 'https://608cd7cd14934ea68284d67c9a8b1831@sentry.keenvil.com/10',
};

export default appConfig;
