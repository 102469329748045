import React, { Suspense, lazy } from 'react';
import { HashRouter as Routes, Route, Switch } from 'react-router-dom';
import Loader from './components/loader';
import AppConfig from './config/appConfig';
import { RecoilRoot } from 'recoil';

import './App.css';

AppConfig.setupHost();

const Invitation = lazy(() => import('./routes/invitation'));
const Selfie = lazy(() => import('./routes/selfie'));
const SkipSelfie = lazy(() => import('./routes/skipSelfie'));
const Photo = lazy(() => import('./routes/photo'));
const Form = lazy(() => import('./routes/form'));
const Details = lazy(() => import('./routes/details'));
const ResetPassword = lazy(() => import('./routes/passwordReset'));

const createRouteLoadingIndicator = () => {
  return (
    <div className="route-loader">
      <Loader />
    </div>
  );
};

export default () => {
  return (
    <RecoilRoot>
      <Routes>
        <div className="main-outlet">
          <div className="main-outlet__container">
            <Suspense fallback={createRouteLoadingIndicator()}>
              <Switch>
                <Route
                  exact
                  path="/c/:communityId/invitations/:invitationId"
                  component={Invitation}
                />
                <Route exact path="/selfie" component={Selfie} />
                <Route exact path="/skipselfie" component={SkipSelfie} />
                <Route exact path="/photo" component={Photo} />
                <Route exact path="/form" component={Form} />
                <Route exact path="/details" component={Details} />
                <Route
                  exact
                  path="/passwordReset/:resetCode"
                  component={ResetPassword}
                />
              </Switch>
            </Suspense>
          </div>
        </div>
      </Routes>
    </RecoilRoot>
  );
};
