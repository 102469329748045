import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';
import AppConfig from './config/appConfig';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

Raven.config(AppConfig.sentryDsn).install();
Raven.context(function () {
  ReactDOM.render(<App />, document.getElementById('root'));
});
