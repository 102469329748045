import React from 'react';

import './styles.css';

const Loader = ({ color = 'white' }) => {
  return (
    <div className="spinner">
      <div
        className="spinner__double-bounce1"
        style={{ backgroundColor: color }}
      />
      <div
        className="spinner__double-bounce2"
        style={{ backgroundColor: color }}
      />
    </div>
  );
};

export default Loader;
